import React, { useMemo } from 'react'
import styled from 'styled-components'
import Panel from '../Panel'
import { AutoColumn } from '../Column'
import { RowFixed } from '../Row'
import { TYPE } from '../../Theme'
import { usePairData } from '../../contexts/PairData'
import { formattedNum } from '../../utils'

const PriceCard = styled(Panel)`
  position: absolute;
  right: -220px;
  width: 220px;
  top: -20px;
  z-index: 9999;
  height: fit-content;
  background-color: ${({ theme }) => theme.bg1};
`

function formatPercent(rawPercent) {
  if (rawPercent < 0.01) {
    return '<1%'
  } else return parseFloat(rawPercent * 100).toFixed(0) + '%'
}

export default function UniPrice() {
  const usdcPair = usePairData('0x0d190fA9649CE9b59d466AF959A458ef3A06897b')

  const totalLiquidity = useMemo(() => {
    return usdcPair ? usdcPair.trackedReserveUSD : 0
  }, [usdcPair])

  const usdcPerOmc = usdcPair ? parseFloat(usdcPair.token0Price).toFixed(2) : '-'

  return (
    <PriceCard>
      <AutoColumn gap="5px">
        <RowFixed>
          <TYPE.main>USDT.b/OMC: {formattedNum(usdcPerOmc, true)}</TYPE.main>
          <TYPE.light style={{ marginLeft: '10px' }}>
            {usdcPair && totalLiquidity ? formatPercent(usdcPair.trackedReserveUSD / totalLiquidity) : '-'}
          </TYPE.light>
        </RowFixed>
      </AutoColumn>
    </PriceCard>
  )
}
